<template>
    <b-row>
        <b-col>
            <b-card>
                <b-tabs pills>
                    <b-tab title="Payroll" active>
                        <b-row>
                            <b-col md="4" xl="4">
                                <b-form-group label="Code" label-for="code">
                                    <b-form-input id="code" v-model="form.code" />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Month" label-for="month">
                                    <b-form-select
                                    id="month"
                                    v-model="form.selectedMonth"
                                    :options="form.optionsMonth"
                                    />
                                    <!-- Selected: <strong>{{ form.selectedMonth }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Year" label-for="year">
                                    <b-form-input id="year" v-model="form.year" type="number" />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Type" label-for="type">
                                    <b-form-select
                                    id="type"
                                    v-model="form.selectedType"
                                    :options="form.optionsType"
                                    />
                                    <!-- Selected: <strong>{{ form.selectedType }}</strong> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Items">
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Employee" label-for="employee">
                                    <v-select
                                    id="employee"
                                    v-model="form2.selectedEmployee"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="form2.optionsEmployee"
                                    />
                                    <!-- Selected: <strong>{{ form2.selectedEmployee }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" class="">
                                <label for=""></label>
                                <b-button
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="outline-primary"
                                    block
                                    @click="trigger()"
                                    :disabled="form2.selectedEmployee === null ? true : false"
                                    v-b-modal.modal-add-details
                                    >
                                    Add Details
                                </b-button>
                                <!-- modal add details -->
                                <b-modal
                                id="modal-add-details"
                                title="Add Details"
                                ok-title="Add Details"
                                cancel-variant="outline-secondary"
                                hide-footer
                                >
                                    <validation-observer ref="addDetails">
                                    <b-form ref="form" @submit.prevent>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group
                                                :state="errors"
                                                label="Employee Name"
                                                label-for="employee_name"
                                                invalid-feedback="Employee is required"
                                                >
                                                    <b-form-input 
                                                    id="employee_name"
                                                    disabled 
                                                    v-model="form2.employeeName" />
                                                    <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group
                                                :state="errors"
                                                label="Basic Sallary"
                                                label-for="basic_sallary"
                                                invalid-feedback="Basic Sallary is required"
                                                >
                                                    <b-form-input 
                                                    id="basic_sallary" 
                                                    type="number"
                                                    @keyup="calculate()"
                                                    required
                                                    v-model="form2.basic_sallary" />
                                                    <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="6" lg="6" xl="6">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Allowance</label>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Tax"
                                                        label-for="tax"
                                                        invalid-feedback="Tax is required"
                                                        >
                                                            <b-form-input 
                                                            id="tax" 
                                                            type="number"
                                                            v-model="form2.pph" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Position"
                                                        label-for="position"
                                                        invalid-feedback="Position is required"
                                                        >
                                                            <b-form-input 
                                                            id="position" 
                                                            type="number"
                                                            v-model="form2.position" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Food"
                                                        label-for="food"
                                                        invalid-feedback="Food is required"
                                                        >
                                                            <b-form-input 
                                                            id="food" 
                                                            type="number"
                                                            v-model="form2.food" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Transport"
                                                        label-for="transport"
                                                        invalid-feedback="Transport is required"
                                                        >
                                                            <b-form-input 
                                                            id="transport" 
                                                            type="number"
                                                            v-model="form2.transport" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Telecommunication"
                                                        label-for="telecommunication"
                                                        invalid-feedback="Telecommunication is required"
                                                        >
                                                            <b-form-input 
                                                            id="telecommunication" 
                                                            type="number"
                                                            v-model="form2.telecommunication" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Overtime"
                                                        label-for="overtime"
                                                        invalid-feedback="Overtime is required"
                                                        >
                                                            <b-form-input 
                                                            id="overtime" 
                                                            type="number"
                                                            v-model="form2.overtime" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_health"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_health" 
                                                            type="number"
                                                            @keyup="calculateHealth()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Health"
                                                        label-for="allowance_health"
                                                        invalid-feedback="Health is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_health" 
                                                            type="number"
                                                            v-model="form2.allowance_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jht"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jht" 
                                                            type="number"
                                                            @keyup="calculateJht()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JHT"
                                                        label-for="allowance_jht"
                                                        invalid-feedback="Allowance JHT is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jht" 
                                                            type="number"
                                                            v-model="form2.allowance_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jkk"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jkk" 
                                                            type="number"
                                                            @keyup="calculateJkk()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jkk" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JKK"
                                                        label-for="allowance_jkk"
                                                        invalid-feedback="Allowance JKK is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jkk" 
                                                            type="number"
                                                            v-model="form2.allowance_jkk" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jkm"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jkm" 
                                                            type="number"
                                                            @keyup="calculateJkm()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jkm" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JKM"
                                                        label-for="allowance_jkm"
                                                        invalid-feedback="Allowance JKM is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jkm" 
                                                            type="number"
                                                            v-model="form2.allowance_jkm" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jp"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jp" 
                                                            type="number"
                                                            @keyup="calculateJp()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JP"
                                                        label-for="allowance_jp"
                                                        invalid-feedback="Allowance JP is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jp" 
                                                            type="number"
                                                            v-model="form2.allowance_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance Other"
                                                        label-for="allowance_other"
                                                        invalid-feedback="Allowance Other is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_other" 
                                                            type="number"
                                                            v-model="form2.allowance_other" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Total Allowance"
                                                        label-for="total_allowance"
                                                        invalid-feedback="Total Allowance is required"
                                                        >
                                                            <b-form-input 
                                                            id="total_allowance" 
                                                            type="number"
                                                            disabled
                                                            v-model="total_allowance" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col md="6" lg="6" xl="6">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Deduction</label>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_health2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_health2" 
                                                            type="number"
                                                            @keyup="calculateHealth2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_health2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Health"
                                                        label-for="deduction_health"
                                                        invalid-feedback="Deduction Health is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_health" 
                                                            type="number"
                                                            v-model="form2.deduction_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jht2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jht2" 
                                                            type="number"
                                                            @keyup="calculateJht2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jht2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction JHT"
                                                        label-for="deduction_jht"
                                                        invalid-feedback="Deduction JHT is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_jht" 
                                                            type="number"
                                                            v-model="form2.deduction_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jp2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jp2" 
                                                            type="number"
                                                            @keyup="calculateJp2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jp2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction JP"
                                                        label-for="deduction_jp"
                                                        invalid-feedback="Deduction JP is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_jp" 
                                                            type="number"
                                                            v-model="form2.deduction_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Salary Reduction"
                                                        label-for="reduction"
                                                        invalid-feedback="Salary Reduction is required"
                                                        >
                                                            <b-form-input 
                                                            id="reduction"
                                                            type="number"
                                                            v-model="form2.reduction" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Loan"
                                                        label-for="loan"
                                                        invalid-feedback="Loan is required"
                                                        >
                                                            <b-form-input 
                                                            id="loan"
                                                            type="number"
                                                            v-model="form2.loan" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Other"
                                                        label-for="deduction_other"
                                                        invalid-feedback="Deduction Other is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_other"
                                                            type="number"
                                                            v-model="form2.deduction_other" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Tax"
                                                        label-for="deduction_tax"
                                                        invalid-feedback="Deduction Tax is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_tax"
                                                            type="number"
                                                            disabled
                                                            v-model="deduction_tax" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <label></label>
                                                        <b-button
                                                            class="mt-0"
                                                            type="submit"
                                                            variant="primary"
                                                            block
                                                            @click="checkTax()"
                                                            :disabled="form2.basic_sallary === null ? true : false"
                                                        >
                                                            Check Tax
                                                        </b-button>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Total Deduction"
                                                        label-for="total_deduction"
                                                        invalid-feedback="Total Deduction is required"
                                                        >
                                                            <b-form-input 
                                                            id="total_deduction"
                                                            type="number"
                                                            disabled
                                                            v-model="total_deduction" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="12" lg="12" xl="12">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Take Home Pay</label>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Take Home Pay"
                                                        label-for="take_home_pay"
                                                        invalid-feedback="Take Home Pay is required"
                                                        >
                                                            <b-form-input 
                                                            id="take_home_pay"
                                                            type="number"
                                                            disabled
                                                            v-model="take_home_pay" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        
                                        <b-row>
                                            <b-col>
                                                <b-button
                                                    class="mt-1"
                                                    type="submit"
                                                    variant="primary"
                                                    block
                                                    @click="addEmployee(form2.selectedEmployee.value)"
                                                    :disabled="form2.basic_sallary === null ? true : false"
                                                >
                                                    Add Details
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                    </validation-observer>
                                </b-modal>
                                <!-- end modal add details -->
                            </b-col>
                            <b-col md="12" xl="12">
                                <!-- table -->
                                <vue-good-table
                                :columns="columns"
                                :rows="rows"
                                :rtl="direction"
                                :search-options="{
                                    enabled: true,
                                    externalQuery: searchTerm 
                                }"
                                :select-options="{
                                    enabled: false,
                                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                    selectionInfoClass: 'custom-class',
                                    selectionText: 'rows selected',
                                    clearSelectionText: 'clear',
                                    disableSelectInfo: true, // disable the select info panel on top
                                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                                }"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:pageLength
                                }"
                                >
                                    <template
                                        slot="table-row"
                                        slot-scope="props"
                                    >

                                        <!-- Column: Name -->
                                        <span
                                        v-if="props.column.field === 'name'"
                                        class="text-nowrap"
                                        >
                                            <!-- <b-avatar
                                                :src="props.row.avatar"
                                                class="mx-1"
                                            /> -->
                                            <span class="text-nowrap">{{ props.row.name }}</span>
                                        </span>

                                        <!-- Column: Basic Sallary -->
                                        <span
                                        v-else-if="props.column.field === 'basic_sallary'"
                                        class="text-nowrap"
                                        >
                                            <span class="text-nowrap">{{ formatRupiah(props.row.basic_sallary) }}</span>
                                        </span>

                                        <!-- Column: Take Home Pay -->
                                        <span
                                        v-else-if="props.column.field === 'take_home_pay'"
                                        class="text-nowrap"
                                        >
                                            <span class="text-nowrap">{{ formatRupiah(props.row.take_home_pay) }}</span>
                                        </span>

                                        <!-- Column: Allowance Total -->
                                        <span
                                        v-else-if="props.column.field === 'allowance_total'"
                                        class="text-nowrap"
                                        >
                                            <span class="text-nowrap">{{ formatRupiah(props.row.allowance_total) }}</span>
                                        </span>

                                        <!-- Column: Deduction Total -->
                                        <span
                                        v-else-if="props.column.field === 'deduction_total'"
                                        class="text-nowrap"
                                        >
                                            <span class="text-nowrap">{{ formatRupiah(props.row.deduction_total) }}</span>
                                        </span>

                                        <!-- Column: Status -->
                                        <span v-else-if="props.column.field === 'status'">
                                            <b-badge :variant="statusVariant(props.row.status)">
                                                <span v-if="props.row.status === 1">Active</span>
                                                <span v-else>Non-Active</span>
                                            </b-badge>
                                        </span>

                                        <!-- Column: Action -->
                                        <span v-else-if="props.column.field === 'action'">
                                            <span>
                                                <b-dropdown
                                                variant="link"
                                                toggle-class="text-decoration-none"
                                                no-caret
                                                >
                                                    <template v-slot:button-content>
                                                        <feather-icon
                                                        icon="MoreVerticalIcon"
                                                        size="16"
                                                        class="text-body align-middle mr-25"
                                                        />
                                                    </template>
                                                    <b-dropdown-item 
                                                    v-b-modal.modal-update-details
                                                    @click="showPayrollItem(props.row.id)">
                                                        <feather-icon
                                                        icon="Edit2Icon"
                                                        class="mr-50"
                                                        />
                                                        <span>Detail</span>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item @click="deletePayrollItem(props.row.id)">
                                                        <feather-icon
                                                        icon="TrashIcon"
                                                        class="mr-50"
                                                        />
                                                        <span>Delete</span>
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </span>
                                        </span>

                                        <!-- Column: Common -->
                                        <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>

                                    <!-- pagination -->
                                    <template
                                        slot="pagination-bottom"
                                        slot-scope="props"
                                    >
                                        <div class="d-flex justify-content-between flex-wrap">
                                            <div class="d-flex align-items-center mb-0 mt-1">
                                                <span class="text-nowrap ">
                                                Showing 1 to
                                                </span>
                                                <b-form-select
                                                v-model="pageLength"
                                                :options="['3','5','10']"
                                                class="mx-1"
                                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                                />
                                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                                            </div>
                                            <div>
                                                <b-pagination
                                                :value="1"
                                                :total-rows="props.total"
                                                :per-page="pageLength"
                                                first-number
                                                last-number
                                                align="right"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                                class="mt-1 mb-0"
                                                @input="(value)=>props.pageChanged({currentPage:value})"
                                                >
                                                <template #prev-text>
                                                    <feather-icon
                                                    icon="ChevronLeftIcon"
                                                    size="18"
                                                    />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon
                                                    icon="ChevronRightIcon"
                                                    size="18"
                                                    />
                                                </template>
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </template>
                                </vue-good-table>
                                <!-- modal update details -->
                                <b-modal
                                id="modal-update-details"
                                title="Update Details"
                                ok-title="Update Details"
                                cancel-variant="outline-secondary"
                                hide-footer
                                >
                                    <validation-observer ref="updateDetails">
                                    <b-form ref="form" @submit.prevent>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group
                                                :state="errors"
                                                label="Employee Name"
                                                label-for="employee_name"
                                                invalid-feedback="Employee is required"
                                                >
                                                    <b-form-input 
                                                    id="employee_name"
                                                    disabled 
                                                    v-model="form2.employeeName" />
                                                    <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group
                                                :state="errors"
                                                label="Basic Sallary"
                                                label-for="basic_sallary"
                                                invalid-feedback="Basic Sallary is required"
                                                >
                                                    <b-form-input 
                                                    id="basic_sallary" 
                                                    type="number"
                                                    @keyup="calculate()"
                                                    required
                                                    v-model="form2.basic_sallary" />
                                                    <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="6" lg="6" xl="6">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Allowance</label>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Tax"
                                                        label-for="tax"
                                                        invalid-feedback="Tax is required"
                                                        >
                                                            <b-form-input 
                                                            id="tax" 
                                                            type="number"
                                                            v-model="form2.pph" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Position"
                                                        label-for="position"
                                                        invalid-feedback="Position is required"
                                                        >
                                                            <b-form-input 
                                                            id="position" 
                                                            type="number"
                                                            v-model="form2.position" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Food"
                                                        label-for="food"
                                                        invalid-feedback="Food is required"
                                                        >
                                                            <b-form-input 
                                                            id="food" 
                                                            type="number"
                                                            v-model="form2.food" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Transport"
                                                        label-for="transport"
                                                        invalid-feedback="Transport is required"
                                                        >
                                                            <b-form-input 
                                                            id="transport" 
                                                            type="number"
                                                            v-model="form2.transport" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Telecommunication"
                                                        label-for="telecommunication"
                                                        invalid-feedback="Telecommunication is required"
                                                        >
                                                            <b-form-input 
                                                            id="telecommunication" 
                                                            type="number"
                                                            v-model="form2.telecommunication" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Overtime"
                                                        label-for="overtime"
                                                        invalid-feedback="Overtime is required"
                                                        >
                                                            <b-form-input 
                                                            id="overtime" 
                                                            type="number"
                                                            v-model="form2.overtime" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_health"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_health" 
                                                            type="number"
                                                            @keyup="calculateHealth()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Health"
                                                        label-for="allowance_health"
                                                        invalid-feedback="Health is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_health" 
                                                            type="number"
                                                            v-model="form2.allowance_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jht"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jht" 
                                                            type="number"
                                                            @keyup="calculateJht()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JHT"
                                                        label-for="allowance_jht"
                                                        invalid-feedback="Allowance JHT is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jht" 
                                                            type="number"
                                                            v-model="form2.allowance_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jkk"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jkk" 
                                                            type="number"
                                                            @keyup="calculateJkk()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jkk" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JKK"
                                                        label-for="allowance_jkk"
                                                        invalid-feedback="Allowance JKK is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jkk" 
                                                            type="number"
                                                            v-model="form2.allowance_jkk" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jkm"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jkm" 
                                                            type="number"
                                                            @keyup="calculateJkm()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jkm" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JKM"
                                                        label-for="allowance_jkm"
                                                        invalid-feedback="Allowance JKM is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jkm" 
                                                            type="number"
                                                            v-model="form2.allowance_jkm" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jp"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jp" 
                                                            type="number"
                                                            @keyup="calculateJp()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance JP"
                                                        label-for="allowance_jp"
                                                        invalid-feedback="Allowance JP is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_jp" 
                                                            type="number"
                                                            v-model="form2.allowance_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Allowance Other"
                                                        label-for="allowance_other"
                                                        invalid-feedback="Allowance Other is required"
                                                        >
                                                            <b-form-input 
                                                            id="allowance_other" 
                                                            type="number"
                                                            v-model="form2.allowance_other" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Total Allowance"
                                                        label-for="total_allowance"
                                                        invalid-feedback="Total Allowance is required"
                                                        >
                                                            <b-form-input 
                                                            id="total_allowance" 
                                                            type="number"
                                                            disabled
                                                            v-model="total_allowance" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col md="6" lg="6" xl="6">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Deduction</label>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_health2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_health2" 
                                                            type="number"
                                                            @keyup="calculateHealth2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_health2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Health"
                                                        label-for="deduction_health"
                                                        invalid-feedback="Deduction Health is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_health" 
                                                            type="number"
                                                            v-model="form2.deduction_health" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jht2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jht2" 
                                                            type="number"
                                                            @keyup="calculateJht2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jht2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction JHT"
                                                        label-for="deduction_jht"
                                                        invalid-feedback="Deduction JHT is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_jht" 
                                                            type="number"
                                                            v-model="form2.deduction_jht" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="4">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Percentage ( % )"
                                                        label-for="percentage_jp2"
                                                        invalid-feedback="Percentage is required"
                                                        >
                                                            <b-form-input 
                                                            id="percentage_jp2" 
                                                            type="number"
                                                            @keyup="calculateJp2()"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            v-model="percentage_jp2" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="8">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction JP"
                                                        label-for="deduction_jp"
                                                        invalid-feedback="Deduction JP is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_jp" 
                                                            type="number"
                                                            v-model="form2.deduction_jp" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Salary Reduction"
                                                        label-for="reduction"
                                                        invalid-feedback="Salary Reduction is required"
                                                        >
                                                            <b-form-input 
                                                            id="reduction"
                                                            type="number"
                                                            v-model="form2.reduction" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Loan"
                                                        label-for="loan"
                                                        invalid-feedback="Loan is required"
                                                        >
                                                            <b-form-input 
                                                            id="loan"
                                                            type="number"
                                                            v-model="form2.loan" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Other"
                                                        label-for="deduction_other"
                                                        invalid-feedback="Deduction Other is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_other"
                                                            type="number"
                                                            v-model="form2.deduction_other" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Deduction Tax"
                                                        label-for="deduction_tax"
                                                        invalid-feedback="Deduction Tax is required"
                                                        >
                                                            <b-form-input 
                                                            id="deduction_tax"
                                                            type="number"
                                                            disabled
                                                            v-model="deduction_tax" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="6">
                                                        <label></label>
                                                        <b-button
                                                            class="mt-0"
                                                            type="submit"
                                                            variant="primary"
                                                            block
                                                            @click="checkTax()"
                                                            :disabled="form2.basic_sallary === null ? true : false"
                                                        >
                                                            Check Tax
                                                        </b-button>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Total Deduction"
                                                        label-for="total_deduction"
                                                        invalid-feedback="Total Deduction is required"
                                                        >
                                                            <b-form-input 
                                                            id="total_deduction"
                                                            type="number"
                                                            disabled
                                                            v-model="total_deduction" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="12" lg="12" xl="12">
                                                <b-row>
                                                    <b-col cols="12">
                                                        <label for="" class="font-weight-bolder text-primary my-1">Take Home Pay</label>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <b-form-group
                                                        :state="errors"
                                                        label="Take Home Pay"
                                                        label-for="take_home_pay"
                                                        invalid-feedback="Take Home Pay is required"
                                                        >
                                                            <b-form-input 
                                                            id="take_home_pay"
                                                            type="number"
                                                            disabled
                                                            v-model="take_home_pay" />
                                                            <!-- <small class="text-danger">{{ errMessage }}</small> -->
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        
                                        <b-row>
                                            <b-col>
                                                <b-button
                                                    class="mt-1"
                                                    type="submit"
                                                    variant="primary"
                                                    block
                                                    @click="updatePayrollItem(show_id_payroll_item)"
                                                    :disabled="form2.basic_sallary === null ? true : false"
                                                >
                                                    Update Details
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                    </validation-observer>
                                </b-modal>
                                <!-- end modal update details -->
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
        <!-- submit and reset -->
        <b-col md="12">
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addPayrolls()"
            block
            >
            Submit
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import {
    VBModal, BModal, BTableLite, BDropdownItem, BDropdown, BPagination, BBadge, BTab, BTabs, BAlert, BLink, BFormInvalidFeedback, BFormValidFeedback, BFormRadio, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
    components: {
        BCardActions,
        vSelect,
        VueGoodTable,
        ToastificationContent,
        flatPickr,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BDropdownItem,
        BDropdown,
        BPagination,
        BBadge,
        BTableLite,
        BTab,
        BTabs,
        BAlert,
        BLink,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormRadio,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            form: {
                code: '',
                name: '',
                selectedMonth: null,
                optionsMonth: [
                    { value: null, text: 'Please select month'},
                    { value: '1', text: 'January'},
                    { value: '2', text: 'February'},
                    { value: '3', text: 'March'},
                    { value: '4', text: 'April'},
                    { value: '5', text: 'May'},
                    { value: '6', text: 'June'},
                    { value: '7', text: 'July'},
                    { value: '8', text: 'August'},
                    { value: '9', text: 'September'},
                    { value: '10', text: 'October'},
                    { value: '11', text: 'November'},
                    { value: '12', text: 'December'}
                ],
                year: null,
                selectedType: null,
                optionsType: [
                    { value: null, text: 'Please select type'},
                    { value: '1', text: 'Monthly'},
                    { value: '2', text: 'Once'}
                ],
            },
            form2: {
                selectedEmployee: null,
                optionsEmployee: [],
                employeeName: '',
                employeeId: null,
                basic_sallary: null,
                pph: 0,
                position: 0,
                food: 0,
                transport: 0,
                telecommunication: 0,
                overtime: 0,
                allowance_health: 0,
                allowance_jht: 0,
                allowance_jkk: 0,
                allowance_jkm: 0,
                allowance_jp: 0,
                allowance_other: 0,
                deduction_health: 0,
                deduction_jht: 0,
                deduction_jp: 0,
                reduction: 0,
                loan: 0,
                deduction_other: 0
            },
            percentage_health: 4,
            percentage_jht: 3.7,
            percentage_jkk: 0.24,
            percentage_jkm: 0.3,
            percentage_jp: 0.2,
            percentage_health2: 1,
            percentage_jht2: 2,
            percentage_jp2: 1,
            deduction_tax: 0,
            total_allowance: 0,
            total_deduction: 0,
            take_home_pay: 0,
            payroll_id: null,
            show_id_payroll_item: null,
            pageLength: 10,
            dir: false,
            columns: [
                {
                label: 'Name',
                field: 'employee_name',
                },
                {
                label: 'Basic Sallary',
                field: 'basic_sallary',
                },
                {
                label: 'Take Home Pay',
                field: 'take_home_pay',
                },
                {
                label: 'Allowance Total',
                field: 'allowance_total',
                },
                {
                label: 'Deduction Total',
                field: 'deduction_total',
                },
                {
                label: 'Action',
                field: 'action',
                },
            ],
            table_employee_name: [],
            rows: [],
            searchTerm: '',
            status: [{
                1: 'Current',
                2: 'Professional',
                3: 'Rejected',
                4: 'Resigned',
                5: 'Applied',
            },
            {
                1: 'light-primary',
                2: 'light-success',
                3: 'light-danger',
                4: 'light-warning',
                5: 'light-info',
            }],
            errors: '',
            errMessage1: '',
            errMessage: '',
            show: false
        }
    },
    mounted(that = this) {
        document.getElementsByClassName('close-modal')[0].addEventListener('click', function () {
        that.show = false;
        })
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-success',
                0 : 'light-danger'
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getEmployees()
    },
    updated() {
        //total allowance
        this.total_allowance = parseInt(this.form2.pph) + parseInt(this.form2.position) + parseInt(this.form2.food) + parseInt(this.form2.transport) + parseInt(this.form2.telecommunication) + parseInt(this.form2.overtime) + parseInt(this.form2.allowance_health) + parseInt(this.form2.allowance_jht) + parseInt(this.form2.allowance_jkk) + parseInt(this.form2.allowance_jkm) + parseInt(this.form2.allowance_jp) + parseInt(this.form2.allowance_other)
        //total deduction
        this.total_deduction = parseInt(this.form2.deduction_health) + parseInt(this.form2.deduction_jht) + parseInt(this.form2.deduction_jp) + parseInt(this.form2.reduction) + parseInt(this.form2.loan) + parseInt(this.form2.deduction_other) + parseInt(this.deduction_tax)
        //take home pay
        this.take_home_pay = parseInt(this.form2.basic_sallary) +parseInt(this.form2.pph) + parseInt(this.form2.position) + parseInt(this.form2.food) + parseInt(this.form2.transport) + parseInt(this.form2.telecommunication) + parseInt(this.form2.overtime) + parseInt(this.form2.allowance_other) - parseInt(this.total_deduction)
    },
    methods: {
        formatRupiah(money) {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        },
        getEmployees() {
            this.$http.get('employees')
            .then(response => { 
                this.form2.optionsEmployee.push({value: null, title: 'Please select 1 Employee'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        title: datas[i].name
                    }
                    // if(datas[i].name === 1) {}
                    this.form2.optionsEmployee.push($data)
                }
                // console.log(this.form2.optionsEmployee)
            });
        },
        resetForm() {
            this.form2.basic_sallary = null,
            this.form2.pph = 0,
            this.form2.position = 0,
            this.form2.food = 0,
            this.form2.transport = 0,
            this.form2.telecommunication = 0,
            this.form2.overtime = 0,
            this.form2.allowance_health = 0,
            this.form2.allowance_jht = 0,
            this.form2.allowance_jkk = 0,
            this.form2.allowance_jkm = 0,
            this.form2.allowance_jp = 0,
            this.form2.allowance_other = 0,
            this.form2.deduction_health = 0,
            this.form2.deduction_jht = 0,
            this.form2.deduction_jp = 0,
            this.form2.reduction = 0,
            this.form2.loan = 0,
            this.form2.deduction_other = 0,
            this.form2.deduction_tax = 0
        },
        trigger() {
            this.form2.employeeName = this.form2.selectedEmployee.title
            this.resetForm()
        },
        calculate() {
            var $max_bpjs_health = 12000000
            if(this.form2.basic_sallary > $max_bpjs_health) {
                this.form2.allowance_health = Math.ceil($max_bpjs_health / 100 * this.percentage_health)
            } else {
                this.form2.allowance_health = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_health)
            }
            this.form2.allowance_jht = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jht)
            this.form2.allowance_jkk = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jkk)
            this.form2.allowance_jkm = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jkm)
            this.form2.allowance_jp = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jp)
            if(this.form2.basic_sallary > $max_bpjs_health) {
                this.form2.deduction_health = Math.ceil($max_bpjs_health / 100 * this.percentage_health2)
            } else {
                this.form2.deduction_health = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_health2)
            }
            this.form2.deduction_jht = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jht2)
            this.form2.deduction_jp = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jp2)
        },
        calculateHealth() {
            var $max_bpjs_health = 12000000
            if(this.form2.basic_sallary > $max_bpjs_health) {
                this.form2.allowance_health = Math.ceil($max_bpjs_health / 100 * this.percentage_health)
            } else {
                this.form2.allowance_health = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_health)
            }
        },
        calculateJht() {
            this.form2.allowance_jht = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jht)
        },
        calculateJkk() {
            this.form2.allowance_jkk = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jkk)
        },
        calculateJkm() {
            this.form2.allowance_jkm = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jkm)
        },
        calculateJp() {
            this.form2.allowance_jp = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jp)
        },
        calculateHealth2() {
            var $max_bpjs_health = 12000000
            if(this.form2.basic_sallary > $max_bpjs_health) {
                this.form2.deduction_health = Math.ceil($max_bpjs_health / 100 * this.percentage_health2)
            } else {
                this.form2.deduction_health = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_health2)
            }
        },
        calculateJht2() {
            this.form2.deduction_jht = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jht2)
        },
        calculateJp2() {
            this.form2.deduction_jp = Math.ceil(this.form2.basic_sallary / 100 * this.percentage_jp2)
        },
        checkTax() {
            this.$http.post('payroll/tax', {
                employee_id : this.form2.selectedEmployee.value,
                basic_sallary : this.form2.basic_sallary,
                tax : this.form2.pph,
                position : this.form2.position,
                meal : this.form2.food,
                transportation : this.form2.transport,
                communication : this.form2.telecommunication,
                overtime : this.form2.overtime,
                allowance_health : this.form2.allowance_health,
                allowance_jht : this.form2.allowance_jht,
                allowance_jkk : this.form2.allowance_jkk,
                allowance_jkm : this.form2.allowance_jkm,
                allowance_jp : this.form2.allowance_jp,
                allowance_other : this.form2.allowance_other,
                deduction_health : this.form2.deduction_health,
                deduction_jht : this.form2.deduction_jht,
                deduction_jp : this.form2.deduction_jp
            })
            .then(response => { 
                this.deduction_tax = response.data.data.tax
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Your tax is ' +response.data.data.tax,
                        variant: 'success',
                    },
                })
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        addEmployee() {
            this.$http.post('payroll/item/add', {
                payroll_id : this.payroll_id,
                employee_id : this.form2.selectedEmployee.value,
                basic_sallary : this.form2.basic_sallary,
                tax : this.form2.pph,
                position : this.form2.position,
                meal : this.form2.food,
                transportation : this.form2.transport,
                communication : this.form2.telecommunication,
                overtime : this.form2.overtime,
                allowance_health : this.form2.allowance_health,
                allowance_jht : this.form2.allowance_jht,
                allowance_jkk : this.form2.allowance_jkk,
                allowance_jkm : this.form2.allowance_jkm,
                allowance_jp : this.form2.allowance_jp,
                allowance_other : this.form2.allowance_other,
                deduction_health : this.form2.deduction_health,
                deduction_jht : this.form2.deduction_jht,
                deduction_jp : this.form2.deduction_jp,
                reduction : this.form2.reduction,
                loan : this.form2.loan,
                deduction_other : this.form2.deduction_other,
                deduction_tax : this.form2.deduction_tax
            })
            .then(response => { 
                // console.log(response.data.data)
                this.payroll_id = response.data.data.payroll_id
                this.tableList(this.payroll_id)
                this.form2.optionsEmployee.splice(0)
                this.checkEmployeeAfterAddPayrollItem()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Payroll Item',
                        variant: 'success',
                    },
                })
                document.getElementById("modal-add-details").click();
                this.resetForm()
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
        tableList($payroll_id) {
            this.$http.get('payroll/'+$payroll_id+'/items')
            .then(response => { 
                // console.log(response.data.data)
                this.rows = response.data.data
            });
        },
        checkEmployeeAfterAddPayrollItem() {
            this.$http.get('employees')
            .then(response => { 
                this.form2.optionsEmployee.push({value: null, title: 'Please select 1 Employee'})

                var datas = response.data.data
                var i
                for(i=0; i<this.rows.length; i++) {
                    var $data = this.rows[i].employee_name
                    this.table_employee_name.push($data)
                    // console.log(this.table_employee_name)
                } 
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        title: datas[i].name
                    }
                    if(this.table_employee_name.includes(datas[i].name)) {
                        // console.log('skip')
                    } else {
                        this.form2.optionsEmployee.push($data)
                    }
                }
                // console.log(this.form2.optionsEmployee)
            });
        },
        showPayrollItem($id) {
            this.$http.get('payroll/item/'+$id)
            .then((response) => {
                // console.log(response.data.data)
                this.form2.basic_sallary = response.data.data.basic_sallary,
                this.form2.pph = response.data.data.allowance_tax,
                this.form2.position = response.data.data.position,
                this.form2.food = response.data.data.meal,
                this.form2.transport = response.data.data.transportation,
                this.form2.telecommunication = response.data.data.communication,
                this.form2.overtime = response.data.data.overtime,
                this.form2.allowance_health = response.data.data.allowance_health,
                this.form2.allowance_jht = response.data.data.allowance_jht,
                this.form2.allowance_jkk = response.data.data.allowance_jkk,
                this.form2.allowance_jkm = response.data.data.allowance_jkm,
                this.form2.allowance_jp = response.data.data.allowance_jp,
                this.form2.allowance_other = response.data.data.allowance_other,
                this.total_allowance = response.data.data.allowance_total,
                this.form2.deduction_health = response.data.data.deduction_health,
                this.form2.deduction_jht = response.data.data.deduction_jht,
                this.form2.deduction_jp = response.data.data.deduction_jp,
                this.form2.reduction = response.data.data.deduction_reduction,
                this.form2.loan = response.data.data.deduction_loan,
                this.form2.deduction_other = response.data.data.deduction_other,
                this.deduction_tax = response.data.data.deduction_tax,
                this.total_deduction = response.data.data.deduction_total,
                this.percentage_health = null,
                this.percentage_jht = null,
                this.percentage_jkk = null,
                this.percentage_jkm = null,
                this.percentage_jp = null,
                this.percentage_health2 = null,
                this.percentage_jht2 = null,
                this.percentage_jp2 = null,
                this.show_id_payroll_item = response.data.data.id,
                this.payroll_id = response.data.data.payroll_id,
                this.form2.employeeId = response.data.data.employee_id
                // console.log(this.show_id_payroll_item, this.payroll_id, this.form2.employeeId)
            }).catch((errors) => {
                console.log(errors.response)
            })
        },
        updatePayrollItem($id) {
            this.$http.post('payroll/item/'+$id+'/update', {
                payroll_id : this.payroll_id,
                employee_id : this.form2.employeeId,
                basic_sallary : this.form2.basic_sallary,
                tax : this.form2.pph,
                position : this.form2.position,
                meal : this.form2.food,
                transportation : this.form2.transport,
                communication : this.form2.telecommunication,
                overtime : this.form2.overtime,
                allowance_health : this.form2.allowance_health,
                allowance_jht : this.form2.allowance_jht,
                allowance_jkk : this.form2.allowance_jkk,
                allowance_jkm : this.form2.allowance_jkm,
                allowance_jp : this.form2.allowance_jp,
                allowance_other : this.form2.allowance_other,
                deduction_health : this.form2.deduction_health,
                deduction_jht : this.form2.deduction_jht,
                deduction_jp : this.form2.deduction_jp,
                reduction : this.form2.reduction,
                loan : this.form2.loan,
                deduction_other : this.form2.deduction_other,
                deduction_tax : this.form2.deduction_tax
            })
            .then(response => { 
                // console.log(response.data.data)
                this.payroll_id = response.data.data.payroll_id
                this.tableList(this.payroll_id)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Payroll Item',
                        variant: 'success',
                    },
                })
                document.getElementById("modal-update-details").click();
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
        deletePayrollItem($id) {
            this.$http.post('payroll/item/'+$id+'/delete')
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Payroll Item has been Deleted',
                        variant: 'danger',
                    },
                })
                var $datas = this.rows
                var i
                for(i=0; i<$datas.length; i++) {
                    if($datas[i].id === $id) {
                        this.rows.splice(i, 1)
                    } else {}
                }
                this.form2.optionsEmployee.splice(0)
                this.checkEmployeeAfterAddPayrollItem()
                // location.reload()
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
        addPayrolls() {
            this.$http.post('payrolls/add', {
                code: this.form.code,
                name: this.form.name,
                month: this.form.selectedMonth,
                year: this.form.year,
                type: this.form.selectedType
            })
            .then((response) => {
                this.payroll_id = response.data.data.id
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Payroll',
                        variant: 'success',
                    },
                })
                location.href = "/payrolls"
                // console.log(response.data)
            }).catch((errors) => {
                this.errMessage1 = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage1,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
    #modal-add-details .modal-dialog.modal-md {
        max-width: 1000px !important;
        max-width: max-content;
    }
    #modal-update-details .modal-dialog.modal-md {
        max-width: 1000px !important;
        max-width: max-content;
    }
</style>